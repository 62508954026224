import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

const AnimatedDeliveryIcon = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.3,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        mb: 2,
      }}
    >
      <Box sx={{ position: "relative", width: 200, height: 200, overflow: "hidden" }}>
        <svg viewBox="0 0 200 200" style={{ width: "100%", height: "100%" }}>
          <g className={isVisible ? "truck-animation" : "truck-hidden"}>
            {/* Main cargo box - moved up and made larger */}
            <path
              d="M35,70 h80 v55 h-80 z"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="4"
              strokeLinejoin="round"
            />
            {/* Cargo box detail line */}
            <line x1="35" y1="88" x2="115" y2="88" stroke="#e9c78c" strokeWidth="1.5" />
            {/* Cabin - adjusted to match new cargo box size */}
            <path
              d="M115,82 h30 v43 h-30"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="4"
              strokeLinejoin="round"
            />
            {/* Cabin window detail */}
            <path d="M120,88 h20 v18" fill="none" stroke="#e9c78c" strokeWidth="1.5" />
            {/* Wheels - adjusted position for new truck size */}
            <circle cx="60" cy="135" r="8" fill="none" stroke="#e9c78c" strokeWidth="4" />
            <circle cx="130" cy="135" r="8" fill="none" stroke="#e9c78c" strokeWidth="4" />
          </g>
        </svg>
      </Box>

      <style>
        {`
          .truck-hidden {
            opacity: 0;
            transform: translateX(-100%);
          }

          .truck-animation {
            animation: truck-movement 9s linear infinite;
          }

          @keyframes truck-movement {
            0% {
              transform: translateX(-100%);
              opacity: 0;
            }
            5% {
              transform: translateX(0%);
              opacity: 1;
            }
            65% {
              transform: translateX(0%);
              opacity: 1;
            }
            70% {
              transform: translateX(100%);
              opacity: 0;
            }
            100% {
              transform: translateX(100%);
              opacity: 0;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default AnimatedDeliveryIcon;
