// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import IconCounterCard from "examples/Cards/CounterCards/IconCounterCard";
import AnimatedDiscoveryIcon from "./discoverIcon";
import AnimatedDeliveryIcon from "./truckIcon";
import AnimatedMacroTrackingIcon from "./macroIcon";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <AnimatedDiscoveryIcon />
            <IconCounterCard
              color="dark"
              suffix="."
              title="AI-Powered Meal Discovery"
              description="Find exactly what to eat through intuitive, guided choices. CHEF AI learns your tastes, making personalized suggestions effortless."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimatedDeliveryIcon />
            <IconCounterCard
              color="dark"
              suffix="."
              title="Fresh Groceries at Your Door in Minutes"
              description="Get top-quality, fresh ingredients delivered fast. We portion your order to match your meal plan, so you have just what you need for each dish."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimatedMacroTrackingIcon />
            <IconCounterCard
              count={3}
              color="dark"
              suffix="."
              title="Automatic and Effortless Macro Tracking"
              description="Get precise macro logging without lifting a finger. Our AI-accelerated sensors automatically log your meal's macros so you can stay on top of your nutritional goals."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
