import React, { useEffect, useRef, useState } from "react";
import { GiPizzaSlice, GiHamburger, GiChickenLeg } from "react-icons/gi";
import Box from "@mui/material/Box";

const AnimatedDiscoveryIcon = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.3, // Trigger when 30% of the element is visible
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        mb: 2,
      }}
    >
      <Box sx={{ position: "relative", width: 200, height: 200 }}>
        <svg viewBox="0 0 200 200" style={{ width: "100%", height: "100%" }}>
          <g>
            <circle
              cx="100"
              cy="100"
              r="40"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="4"
              className={isVisible ? "magnifying-glass-circle" : "magnifying-glass-circle-hidden"}
            />
            <path
              d="M128,128 L160,160"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="6"
              className={isVisible ? "magnifying-glass-handle" : "magnifying-glass-handle-hidden"}
            />
            <circle
              cx="100"
              cy="100"
              r="36"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="1"
              className={isVisible ? "inner-detail" : "inner-detail-hidden"}
            />
            <circle
              cx="160"
              cy="160"
              r="3"
              fill="#e9c78c"
              className={isVisible ? "handle-detail" : "handle-detail-hidden"}
            />
          </g>
        </svg>

        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: 80,
            height: 80,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0,
              animation: isVisible ? "show-first 9s infinite" : "none",
              animationDelay: "2s", // Start after magnifying glass animation
            }}
          >
            <GiPizzaSlice size={40} color="#e9c78c" />
          </Box>

          <Box
            sx={{
              position: "absolute",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0,
              animation: isVisible ? "show-second 9s infinite" : "none",
              animationDelay: "2s",
            }}
          >
            <GiHamburger size={40} color="#e9c78c" />
          </Box>

          <Box
            sx={{
              position: "absolute",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0,
              animation: isVisible ? "show-third 9s infinite" : "none",
              animationDelay: "2s",
            }}
          >
            <GiChickenLeg size={40} color="#e9c78c" />
          </Box>
        </Box>
      </Box>

      <style>
        {`
          .magnifying-glass-circle-hidden {
            stroke-dasharray: 251;
            stroke-dashoffset: 251;
          }

          .magnifying-glass-handle-hidden {
            stroke-dasharray: 45;
            stroke-dashoffset: 45;
          }

          .inner-detail-hidden, .handle-detail-hidden {
            opacity: 0;
          }

          .magnifying-glass-circle {
            stroke-dasharray: 251;
            stroke-dashoffset: 251;
            animation: draw-circle 1.5s ease-in-out forwards;
          }

          .magnifying-glass-handle {
            stroke-dasharray: 45;
            stroke-dashoffset: 45;
            animation: draw-handle 0.5s ease-in-out 1s forwards;
          }

          .inner-detail {
            opacity: 0;
            animation: fade-in 0.5s ease-in-out 1.5s forwards;
          }

          .handle-detail {
            opacity: 0;
            animation: fade-in 0.5s ease-in-out 1.5s forwards;
          }

          @keyframes draw-circle {
            to {
              stroke-dashoffset: 0;
            }
          }

          @keyframes draw-handle {
            to {
              stroke-dashoffset: 0;
            }
          }

          @keyframes fade-in {
            to {
              opacity: 1;
            }
          }

          @keyframes show-first {
            0%, 2% { opacity: 0; }
            3%, 30% { opacity: 1; }
            32%, 100% { opacity: 0; }
          }

          @keyframes show-second {
            0%, 32% { opacity: 0; }
            35%, 63% { opacity: 1; }
            65%, 100% { opacity: 0; }
          }

          @keyframes show-third {
            0%, 65% { opacity: 0; }
            68%, 96% { opacity: 1; }
            98%, 100% { opacity: 0; }
          }
        `}
      </style>
    </Box>
  );
};

export default AnimatedDiscoveryIcon;
