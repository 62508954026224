//

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
// import InfoIcon from "@mui/icons-material/Info";
// import prototypeImage from "assets/images/full-device.jpg";
import prototypeImage from "assets/images/prototype-3.jpeg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="precision_manufacturing"
                    title="Fully Automatic"
                    description="Cook food automatically with the single press of a button."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="settings_sharp"
                    title="Fully Integrated"
                    description="Our solution applies to any air fryer!"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="restaurant_menu"
                    title="Perfectly cooked food"
                    description="Our state of the art algorithms, monitoring and feedback systems ensure a perfect cook, every time."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="lock"
                    title="Safety Built In"
                    description="Never worry about overcooking again"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={prototypeImage}
              title="See Our Product"
              description="Get early access to our foundational auto air fryer"
              action={{
                type: "internal",
                route: "http://chefaicookware.com/updates",
                color: "gold",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
