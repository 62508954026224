import { FaSpinner } from "react-icons/fa";

export const Spinner = () => {
  return (
    <div className="Spinner">
      <FaSpinner
        style={{
          animation: "spin 2s linear infinite",
        }}
      />
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};
