//

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography variant="h3" mb={3}>
            CHEF AI feature releases and product alerts
          </MKTypography>
          <MKTypography variant="h5" mt={6} mb={3}>
            January 1st 2024 - We are heading to CES!
          </MKTypography>
          <MKTypography variant="body2" color="text">
            CHEF AI is exhibiting at CES 2024 in Las Vegas! We are ecstatic to show off our product
            to the world and get feedback from our customers. We will be at booth{" "}
            <a
              href="https://exhibitors.ces.tech/8_0/floorplan/?hallID=M&selectedBooth=61044"
              target="_blank"
              rel="noreferrer"
            >
              [61004]
            </a>{" "}
            on the first floor of the Venetian. Please stop by and we hope to see you there!
          </MKTypography>
          <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            November 21st 2023 - We are Patent Pending
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We successfully filed our patent application with the USPTO. We are excited to see what
            the future holds for our company.
          </MKTypography>
          <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            October 20th 2023 - Third Prototype from China
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We got our third prototype from China and integrated our sensors and software. We are
            working hard to get these in beta ready state to send out to our loyal backers and start
            cooking and ingesting data.
          </MKTypography>
          <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            September 14th 2023 - Feedback System
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We created the first iteration of our smart feedback system. Look out for more details
            in our next YouTube video.
          </MKTypography>
          <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            September 3rd 2023 - Prototype Updates
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We successfully completed our second prototype with sensors integrated into a real air
            fryer. Additionally, we added the capability to cook fries. We will continue to refine
            the prototype and add more foods to our system.
          </MKTypography>
          <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            August 18th 2023 - Going public
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We officially deployed our website to the public and will continue to post updates on
            this page.
          </MKTypography>
          <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            August 15th 2023 - The beginning
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We&apos;re excited to launch our new webpage, our central source of information for all
            our loyal backers on Kickstarter!
          </MKTypography>
          <br /> <br />
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
