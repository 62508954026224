import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Icons
import { BsEyeFill } from "react-icons/bs";
import { BiChip } from "react-icons/bi";
import { AiOutlineSync } from "react-icons/ai";

// Sections and components
import Animation from "./animation";
import AnimatedChefHatIcon from "./sections/zeroTouchIcon";
import Newsletter from "pages/presentation/sections/Newsletter";
import Counters from "pages/presentation/sections/Counters";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Presentation() {
  const theme = useTheme();

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <Animation />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* Hero Video Section */}
        <MKBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={20}
          mb={12}
          bgcolor="grey.900"
          py={8}
          borderRadius="xl"
        >
          <iframe
            width="80%"
            height="600px"
            src="https://www.youtube.com/embed/oKfMG9T19VA?autoplay=1&loop=1&playlist=oKfMG9T19VA"
            title="CHEF AI Demo Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{ borderRadius: "12px" }}
          />
        </MKBox>

        {/* Zero-Touch Cooking Section */}
        <Container>
          <MKBox textAlign="center" mb={8}>
            <MKTypography variant="h2" fontWeight="bold" color="gold" mb={2}>
              Zero-Touch Cooking
            </MKTypography>
            <MKTypography variant="h5" color="text" px={6}>
              Experience the future of cooking with AI-powered precision and hands-free convenience
            </MKTypography>
          </MKBox>
          {/* How it Works Section */}
          <MKBox mb={8}>
            <MKTypography variant="h3" textAlign="center" mb={6}>
              How it Works
            </MKTypography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <MKBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                  p={3}
                >
                  <BsEyeFill
                    size={48}
                    color={theme.palette.gold.main}
                    style={{ marginBottom: "1rem" }}
                  />
                  <MKTypography variant="h4" mb={2}>
                    Vision Model
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    Advanced computer vision recognizes your ingredients and monitors your cooking
                    progress in real-time.
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MKBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                  p={3}
                >
                  <BiChip
                    size={48}
                    color={theme.palette.gold.main}
                    style={{ marginBottom: "1rem" }}
                  />
                  <MKTypography variant="h4" mb={2}>
                    Thermal Model
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    Precise temperature control and monitoring ensures perfect cooking results every
                    time.
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MKBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                  p={3}
                >
                  <AiOutlineSync
                    size={48}
                    color={theme.palette.gold.main}
                    style={{ marginBottom: "1rem" }}
                  />
                  <MKTypography variant="h4" mb={2}>
                    Feedback System
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    Real-time adjustments and guidance ensure consistent results.
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>

          {/* Value Proposition Section */}
          <MKBox mb={8}>
            <Container>
              {/* Header */}
              <MKBox textAlign="center" mb={6}>
                <MKTypography
                  variant="h2"
                  color="gold"
                  mb={2}
                  sx={{
                    fontWeight: 700,
                    textTransform: "uppercase",
                    letterSpacing: 1,
                    textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                  }}
                >
                  Zero-Touch Cooking
                </MKTypography>
              </MKBox>

              {/* Icon Section */}
              <MKBox
                display="flex"
                justifyContent="center"
                mb={6}
                sx={{
                  transform: "scale(1.2)",
                  filter: "drop-shadow(0px 4px 6px rgba(0,0,0,0.1))",
                }}
              >
                <AnimatedChefHatIcon />
              </MKBox>

              {/* Benefits Grid */}
              <Grid container spacing={3} mb={6}>
                {[
                  {
                    title: "Cook Without Watching",
                    description: "Free up your time while meals cook perfectly",
                  },
                  {
                    title: "Perfect Results",
                    description: "Achieve consistent, delicious results every time",
                  },
                  {
                    title: "Peace of Mind",
                    description: "No more timing mistakes or burnt food",
                  },
                  {
                    title: "Live Better",
                    description: "Focus on what matters while dinner cooks itself",
                  },
                ].map(({ title, description }) => (
                  <Grid item xs={12} md={6} key={title}>
                    <MKBox
                      p={3}
                      borderRadius="lg"
                      sx={{
                        background:
                          "linear-gradient(to bottom right, rgba(255,255,255,0.9), rgba(255,255,255,0.7))",
                        backdropFilter: "blur(10px)",
                        boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
                        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          boxShadow: "0 6px 12px rgba(0,0,0,0.08)",
                        },
                      }}
                    >
                      <MKTypography
                        variant="h5"
                        color="gold"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                          fontWeight: 600,
                        }}
                      >
                        <MKBox
                          component="span"
                          width={6}
                          height={6}
                          mr={2}
                          borderRadius="50%"
                          bgcolor="gold.main"
                          sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
                        />
                        {title}
                      </MKTypography>
                      <MKTypography
                        variant="body2"
                        color="text"
                        ml={4}
                        sx={{
                          opacity: 0.8,
                          letterSpacing: 0.2,
                        }}
                      >
                        {description}
                      </MKTypography>
                    </MKBox>
                  </Grid>
                ))}
              </Grid>

              {/* Bottom Summary */}
              <MKBox
                textAlign="center"
                px={{ xs: 2, md: 8, lg: 16 }}
                py={4}
                sx={{
                  background:
                    "linear-gradient(45deg, rgba(218,165,32,0.05) 0%, rgba(218,165,32,0.1) 100%)",
                  borderRadius: "xl",
                  boxShadow: "inset 0 2px 4px rgba(0,0,0,0.05)",
                }}
              >
                <MKTypography
                  variant="h6"
                  color="text"
                  sx={{
                    lineHeight: 1.8,
                    fontWeight: 400,
                    opacity: 0.9,
                  }}
                >
                  Enjoy meals that cook themselves. Our smart kitchen tech handles everything, so
                  you can relax while delicious dishes are prepared hands-free.
                </MKTypography>
              </MKBox>
            </Container>
          </MKBox>
        </Container>

        <Divider sx={{ my: 6 }} />

        <Counters />
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;

// // @mui material components
// import Card from "@mui/material/Card";
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";

// // Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
// // import MKTypography from "components/MKTypography";

// // Material Kit 2 PRO React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// // Presentation page sections
// import Counters from "pages/presentation/sections/Counters";
// import Newsletter from "pages/presentation/sections/Newsletter";

// // Routes
// import routes from "routes";
// import footerRoutes from "footer.routes";

// // Animation
// import IconCounterCard from "examples/Cards/CounterCards/IconCounterCard";
// import Animation from "./animation";
// import AnimatedChefHatIcon from "./sections/zeroTouchIcon";

// function Presentation() {
//   return (
//     <>
//       <DefaultNavbar routes={routes} transparent light />
//       <Animation />
//       <Card
//         sx={{
//           p: 2,
//           mx: { xs: 2, lg: 3 },
//           mt: -8,
//           mb: 4,
//           backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
//           backdropFilter: "saturate(200%) blur(30px)",
//           boxShadow: ({ boxShadows: { xxl } }) => xxl,
//         }}
//       >
//         <MKBox display="flex" alignItems="center" justifyContent="center" mt={20} mb={12}>
//           <iframe
//             width="600px"
//             height="400px"
//             src="https://www.youtube.com/embed/oKfMG9T19VA?autoplay=1&loop=1&playlist=oKfMG9T19VA"
//             title="CHEF AI Demo Video"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowFullScreen
//           ></iframe>
//         </MKBox>
//         <MKBox component="section" py={3}>
//           <Container>
//             <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
//               <Grid item xs={12} md={4}>
//                 <AnimatedChefHatIcon />
//                 <IconCounterCard
//                   count={3}
//                   color="dark"
//                   suffix="."
//                   title="Zero-Touch Cooking"
//                   description="Enjoy meals that cook themselves. Our smart kitchen tech handles everything, so you can relax while delicious dishes are prepared hands-free."
//                 />
//               </Grid>
//             </Grid>
//           </Container>
//         </MKBox>
//         <Counters />
//         <Newsletter />
//       </Card>
//       <MKBox pt={6} px={1} mt={6}>
//         <DefaultFooter content={footerRoutes} />
//       </MKBox>
//     </>
//   );
// }

// export default Presentation;
