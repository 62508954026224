import React, { useEffect, useRef, useState } from "react";
import { TbChefHat } from "react-icons/tb";
import Box from "@mui/material/Box";

const AnimatedChefHatIcon = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.3,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        mb: 2,
      }}
    >
      <Box sx={{ position: "relative", width: 200, height: 200 }}>
        {/* Base Hat Icon */}
        <TbChefHat
          size={120}
          color="#e9c78c"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            strokeWidth: 1,
            opacity: isVisible ? 1 : 0,
            transition: "opacity 1s ease-in-out",
          }}
        />

        {/* Circuit Animation Layer */}
        <svg
          viewBox="0 0 200 200"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        >
          <g
            className={isVisible ? "circuit-visible" : "circuit-hidden"}
            transform="translate(0, -15)"
          >
            {/* Center square */}
            <path
              d="M95,100 L105,100 L105,110 L95,110 Z"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="1.5"
              className="circuit-line-1"
            />

            {/* Left circuit */}
            <path
              d="M75,95 L75,115 L85,115 L85,105 L95,105"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="1.5"
              className="circuit-line-2"
            />

            {/* Right circuit */}
            <path
              d="M125,95 L125,115 L115,115 L115,105 L105,105"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="1.5"
              className="circuit-line-3"
            />

            {/* Vertical connections */}
            <path
              d="M95,95 L95,100 M105,95 L105,100"
              fill="none"
              stroke="#e9c78c"
              strokeWidth="1.5"
              className="circuit-line-4"
            />

            {/* Circuit nodes */}
            <circle cx="75" cy="95" r="1" fill="#e9c78c" className="circuit-node-1" />
            <circle cx="75" cy="115" r="1" fill="#e9c78c" className="circuit-node-2" />
            <circle cx="85" cy="105" r="1" fill="#e9c78c" className="circuit-node-3" />
            <circle cx="95" cy="95" r="1" fill="#e9c78c" className="circuit-node-4" />
            <circle cx="105" cy="95" r="1" fill="#e9c78c" className="circuit-node-5" />
            <circle cx="125" cy="95" r="1" fill="#e9c78c" className="circuit-node-6" />
            <circle cx="125" cy="115" r="1" fill="#e9c78c" className="circuit-node-7" />
            <circle cx="115" cy="105" r="1" fill="#e9c78c" className="circuit-node-8" />
            <circle cx="95" cy="105" r="1" fill="#e9c78c" className="circuit-node-9" />
            <circle cx="105" cy="105" r="1" fill="#e9c78c" className="circuit-node-10" />
          </g>
        </svg>
      </Box>

      <style>
        {`
          .circuit-hidden {
            opacity: 0;
          }

          .circuit-visible {
            opacity: 1;
          }

          .circuit-line-1, .circuit-line-2, .circuit-line-3, .circuit-line-4 {
            stroke-dasharray: 100;
            stroke-dashoffset: 100;
            animation: draw-line 9s infinite;
          }

          .circuit-line-2 {
            animation-delay: 0.75s;
          }

          .circuit-line-3 {
            animation-delay: 1.5s;
          }

          .circuit-line-4 {
            animation-delay: 2.25s;
          }

          .circuit-node-1, .circuit-node-2, .circuit-node-3,
          .circuit-node-4, .circuit-node-5, .circuit-node-6,
          .circuit-node-7, .circuit-node-8, .circuit-node-9,
          .circuit-node-10 {
            animation: pulse 9s infinite;
          }

          @keyframes draw-line {
            0%, 100% {
              stroke-dashoffset: 100;
              opacity: 0;
            }
            10%, 90% {
              stroke-dashoffset: 0;
              opacity: 1;
            }
          }

          @keyframes pulse {
            0%, 100% {
              opacity: 0;
              r: 1.5;
            }
            10%, 90% {
              opacity: 1;
              r: 1.5;
            }
            50% {
              opacity: 1;
              r: 2.5;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default AnimatedChefHatIcon;
