import React, { useEffect, useRef, useState } from "react";
import { FaFish } from "react-icons/fa";
import Box from "@mui/material/Box";

const AnimatedMacroTrackingIcon = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.3,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        mb: 2,
      }}
    >
      <Box sx={{ position: "relative", width: 200, height: 200 }}>
        <svg viewBox="0 0 200 200" style={{ width: "100%", height: "100%" }}>
          {/* Plate base */}
          <ellipse
            cx="100"
            cy="100"
            rx="53"
            ry="53"
            fill="none"
            stroke="#e9c78c"
            strokeWidth="1.5"
            className={isVisible ? "plate-draw" : "plate-hidden"}
          />

          {/* Plate rim detail */}
          <ellipse
            cx="100"
            cy="100"
            rx="43"
            ry="43"
            fill="none"
            stroke="#e9c78c"
            strokeWidth="0.75"
            className={isVisible ? "plate-detail" : "plate-hidden"}
          />

          {/* Scanning line */}
          <line
            x1="47"
            y1="100"
            x2="153"
            y2="100"
            stroke="#e9c78c"
            strokeWidth="0.75"
            className={isVisible ? "scan-line" : "scan-hidden"}
          />

          {/* Macro text */}
          <g className={isVisible ? "macro-text" : "macro-hidden"}>
            <text x="100" y="85" fill="#e9c78c" fontSize="12" textAnchor="middle">
              Fat - 7g
            </text>
            <text x="100" y="100" fill="#e9c78c" fontSize="12" textAnchor="middle">
              Protein - 20g
            </text>
            <text x="100" y="115" fill="#e9c78c" fontSize="12" textAnchor="middle">
              Carbs - 0g
            </text>
          </g>
        </svg>

        {/* Centered fish icon */}
        <div
          className={isVisible ? "fish-container" : "fish-hidden"}
          style={{
            position: "absolute",
            left: "50%",
            top: "52%",
            transform: "translate(-50%, -50%)",
            opacity: 0,
          }}
        >
          <FaFish size={42} color="#e9c78c" />
        </div>
      </Box>

      <style>
        {`
          .plate-hidden {
            opacity: 0;
          }

          .plate-draw {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: draw-plate 1.5s ease-out forwards;
          }

          .plate-detail {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: draw-plate 1.5s ease-out 0.3s forwards;
          }

          .scan-hidden, .fish-hidden {
            opacity: 0;
          }

          .scan-line {
            animation: scan-movement 9s infinite;
          }

          .fish-container {
            animation: show-fish 9s infinite;
          }

          .macro-text {
            opacity: 0;
            animation: show-macros 9s infinite;
          }

          @keyframes draw-plate {
            to {
              stroke-dashoffset: 0;
              opacity: 1;
            }
          }

          @keyframes scan-movement {
            0%, 2%, 100% {
              transform: translateY(-26px);
              opacity: 0;
            }
            5% {
              transform: translateY(-26px);
              opacity: 1;
            }
            25% {
              transform: translateY(26px);
              opacity: 1;
            }
            45% {
              transform: translateY(-26px);
              opacity: 1;
            }
            47%, 100% {
              opacity: 0;
            }
          }

          @keyframes show-macros {
            0%, 52% {
              opacity: 0;
            }
            55%, 95% {
              opacity: 1;
            }
            98%, 100% {
              opacity: 0;
            }
          }

          @keyframes show-fish {
            0%, 47% {
              opacity: 1;
            }
            50%, 95% {
              opacity: 0;
            }
            98%, 100% {
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default AnimatedMacroTrackingIcon;
