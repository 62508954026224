// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function IconCounterCard({ title, description }) {
  return (
    <MKBox p={2} textAlign="center" lineHeight={1}>
      {title && (
        <MKTypography variant="h5" mb={1}>
          {title}
        </MKTypography>
      )}
      {description && (
        <MKTypography variant="body2" color="text">
          {description}
        </MKTypography>
      )}
    </MKBox>
  );
}

// Setting default props for the IconCounterCard
IconCounterCard.defaultProps = {
  color: "primary",
  description: "",
  title: "",
};

// Typechecking props for the IconCounterCard
IconCounterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "gold",
  ]),
  title: PropTypes.string,
  description: PropTypes.string,
};

export default IconCounterCard;
