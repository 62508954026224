// CallbackHandler.js
import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "components/Spinner";

function checkUserExistsInDynamoDB(sub) {
  // Check if the user exists in DynamoDB
  return new Promise((resolve) => {
    fetch(`https://4z1v82nr8i.execute-api.us-east-1.amazonaws.com/development/users/sub`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sub: sub }),
    })
      .then((response) => {
        resolve(response.status === 200);
      })
      .catch((error) => {
        console.error("Error:", error);
        resolve(false);
      });
  });
}

function storeUserInDynamoDB(userData) {
  // Store the user information in DynamoDB
  return new Promise((resolve) => {
    fetch("https://4z1v82nr8i.execute-api.us-east-1.amazonaws.com/development/users/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        resolve(response.status === 200);
      })
      .catch((error) => {
        console.error("Error:", error);
        resolve(false);
      });
  });
}

function getSuccessRedirectURL(localEnabled, devEnabled, prodEnabled) {
  if (localEnabled) {
    return "http://localhost:3001";
  } else if (devEnabled) {
    return "http://localhost:3001";
  } else if (prodEnabled) {
    return "https://dashboard.chefaicookware.com";
  } else {
    return "https://dashboard.chefaicookware.com";
  }
}

function getFailureRedirectURL(localEnabled, devEnabled, prodEnabled) {
  if (localEnabled) {
    return "http://localhost:3000";
  } else if (devEnabled) {
    return "http://localhost:3000";
  } else if (prodEnabled) {
    return "https://chefaicookware.com";
  } else {
    return "https://chefaicookware.com";
  }
}

const localEnabled = process.env.REACT_APP_LOCAL_ENABLED === "true";
const devEnabled = process.env.REACT_APP_DEV_ENABLED === "true";
const prodEnabled = process.env.REACT_APP_PROD_ENABLED === "true";

const CallbackHandler = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [apiCalled, setApiCalled] = useState(false);
  const [userSub, setUserSub] = useState(null);

  const callAPI = useCallback(async () => {
    if (apiCalled) {
      return;
    }

    try {
      const token = await getAccessTokenSilently();

      if (token !== undefined && user !== undefined) {
        // Check if the user exists in DynamoDB
        const userExists = await checkUserExistsInDynamoDB(user.sub);

        if (!userExists) {
          // User is newly registered
          const userData = {
            sub: user.sub,
            name: user.name,
            picture: user.picture,
            updatedAt: user.updated_at,
            email: user.email,
          };

          // Store the user information in DynamoDB
          const userStored = await storeUserInDynamoDB(userData);

          if (userStored) {
            console.log("Registration successful");
            if (localEnabled || devEnabled) {
              try {
                const response = await fetch("http://localhost:3002/save", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ accessToken: token, user }),
                });

                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }

                await response.json();
              } catch (error) {
                console.error("There was a problem with the fetch operation: " + error.message);
              }
            } else if (prodEnabled) {
              localStorage.setItem("accessToken", token);
              localStorage.setItem("user", user);
            }
            window.location.href = getSuccessRedirectURL(localEnabled, devEnabled, prodEnabled);
          } else {
            // Failed to save in DynamoDB
            console.log("Failed to save in DynamoDB");
            window.location.href = getFailureRedirectURL(localEnabled, devEnabled, prodEnabled);
            alert("Database registration failed. Please try again later.");
          }
        } else {
          // User already exists -> Login
          if (localEnabled || devEnabled) {
            await fetch("http://localhost:3002/save", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ accessToken: token, user }),
            });
          } else if (prodEnabled) {
            localStorage.setItem("accessToken", token);
            localStorage.setItem("user", user);
          }
          window.location.href = getSuccessRedirectURL(localEnabled, devEnabled, prodEnabled);
        }
        setApiCalled(true);
      } else {
        console.error("Access token or user is missing");
      }
    } catch (error) {
      console.error("Error:", error);
      window.location.href = getFailureRedirectURL(localEnabled, devEnabled, prodEnabled);
      alert("User registration failed. Please try again.");
    }
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    if (user && user.sub) {
      setUserSub(user.sub);
    }
  }, [user]);

  useEffect(() => {
    if (userSub !== null) {
      callAPI();
    }
  }, [userSub, callAPI]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "69px", // Change this to make the spinner larger or smaller
      }}
    >
      <Spinner />
    </div>
  );
};

export default CallbackHandler;
