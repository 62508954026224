// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function Newsletter() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5} mr="auto">
            <MKTypography variant="h4" mb={1}>
              Book a Demo
            </MKTypography>
            <MKTypography variant="body2" color="text">
              Want to see a live demo? Contact us using the form and we&apos;ll set something up!
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} flexDirection="column" justifyContent="center" ml="auto">
            <MKButton
              variant="gradient"
              color="gold"
              fullWidth
              sx={{ height: "100%", fontSize: "1.5rem", textTransform: "none" }}
              href="http://localhost:3000/contact-us"
              startIcon={
                <Icon style={{ fontSize: "2rem", marginRight: "0.5rem" }}>calendar_today</Icon> // Adjust font size and margin
              }
            >
              Book a Time
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
