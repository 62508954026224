//

import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";s

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Company/AboutUs/sections/Information";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/background-kitchen.jpg";

function AboutUs() {
  const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
            mt={10}
          >
            <MKTypography
              variant="h1"
              mt={4}
              mb={4}
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Our Mission
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={5} mb={24}>
              We are two entrepreneurs seeking to revolutionize cooking. Tired of needing to guess
              and constantly monitor food, we dream of a world where cooking is as simple as
              pressing a button. The CHEF AI system takes the hassle out of cooking, leaving you
              with perfectly cooked meals every time. We&apos;re thrilled to bring this innovative
              product to you, adding a new level of convenience to your daily routine with just one
              push of a button. Get ready to simplify your life with CHEF AI!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={20} pb={12}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Card>
                  <MKBox
                    variant="gradient"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="dark"
                    p={3}
                    mt={-3}
                    mx={2}
                  >
                    <MKTypography variant="h3" color="white">
                      What We Do
                    </MKTypography>
                  </MKBox>
                  <MKBox pb={6} px={6}>
                    <MKTypography variant="body1" color="text" fontWeight="regular" mt={5}>
                      At CHEF AI, we are developing a system to cook food autonomously. With one
                      touch cooking and no need for external probes, convenience is built into the
                      system. This product allows the user to place the food of their choice into
                      the system and based on parameters from sensors, it will cook their food
                      without any intervention, perfectly.
                      <br />
                      <br />
                      The main features of the system include temperature control, time control, and
                      user feedback. Deep learning vision models have been embedded on the device to
                      correctly and accurately classify the category of food being cooked. After
                      identifying the food, we feed the type of food and sensor properties into our
                      application which uses the results of thermal model simulations to determine
                      an accurate initial cooking time. We utilize user feedback after the cooking
                      session to train the system to their desired preference. This is how we ensure
                      the best cook for the customer.
                    </MKTypography>
                  </MKBox>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <Information />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
