//

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";

// Images
import post1 from "assets/images/steak.jpg";
import post2 from "assets/images/openfoam.jpeg";
import post3 from "assets/images/prototype.jpg";

function Posts() {
  return (
    <MKBox component="section" py={7}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={9} textAlign="center" mx="auto">
            <MKTypography variant="h3" mb={0.5}>
              Detailed Release
            </MKTypography>
            <MKTypography variant="body2" color="text" px={{ xs: 0, md: 6 }} mb={4}>
              We are working on articles that go into depth of our developments and processes at
              CHEF AI.
            </MKTypography>
          </Grid>

          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={post1}
              category={{ color: "primary", label: "vision model" }}
              title="Food Scope"
              description="Here we go into a discussion of which foods we want to be able to identify and the plan for executing how to add new foods"
              action={{ type: "internal" }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={post2}
              category={{ color: "info", label: "thermal model" }}
              title="Seeking OpenFOAM expert"
              description="We are looking for someone who can use OpenFOAM or OpenCFD to help us simulate and scale up our initializations"
              action={{ type: "internal" }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={post3}
              category={{ color: "warning", label: "User Feedback System" }}
              title="Our plan to deliver perfect cooking"
              description="Our latest development using user feedback for our user feedback system from exclusive beta prototype users"
              action={{ type: "internal" }}
              // action={{ type: "internal", route: "/" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Posts;
