//

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { useMediaQuery } from "@material-ui/core";

function Information() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const iframeWidth = isMobile ? "100%" : "560";
  const iframeHeight = isMobile ? "315" : "315";

  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} xs={12} lg={8} mx="auto">
          <Grid item xs={12}>
            <MKTypography variant="h3" mb={3}>
              Articles
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">AP News</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://apnews.com/article/ces-2024-robots-vegas-strip-casino-jobs-8bd3fd4f404a0cda90e69e539a19fb01"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://apnews.com/article/ces-2024-robots-vegas-strip-casino-jobs-8bd3fd4f404a0cda90e69e539a19fb01
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">ABC News</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.abc27.com/news/us-world/business/ap-tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking-2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.abc27.com/news/us-world/business/ap-tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking-2/
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Interesting Engineering</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://interestingengineering.com/ces-2024/ai-air-fryer-senses-cooked-food"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://interestingengineering.com/ces-2024/ai-air-fryer-senses-cooked-food
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Wired</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.wired.com/live/ces-2024-liveblog-3/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.wired.com/live/ces-2024-liveblog-3/
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Food Ingredients First</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.foodingredientsfirst.com/news/digital-culinary-innovation-ai-powered-appliances-and-the-future-of-smart-cooking.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.foodingredientsfirst.com/news/digital-culinary-innovation-ai-powered-appliances-and-the-future-of-smart-cooking.html
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Yahoo Finance</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://finance.yahoo.com/news/robot-baristas-ai-chefs-caused-195931613.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://finance.yahoo.com/news/robot-baristas-ai-chefs-caused-195931613.html
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Washington Times</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.washingtontimes.com/news/2024/jan/10/ces-2024-tech-companies-transform-kitchen-with-ai-/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.washingtontimes.com/news/2024/jan/10/ces-2024-tech-companies-transform-kitchen-with-ai-/
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Oregon Live</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.oregonlive.com/business/2024/01/ces-2024-tech-companies-promise-kitchens-with-ai-smarts-and-robots-that-do-the-cooking.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.oregonlive.com/business/2024/01/ces-2024-tech-companies-promise-kitchens-with-ai-smarts-and-robots-that-do-the-cooking.html
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">South China Morning Post</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.scmp.com/lifestyle/food-drink/article/3248085/5-new-kitchen-appliances-ces-2024-smart-ai-grills-no-clean-ice-cream-one-touch-air-fryers-and-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.scmp.com/lifestyle/food-drink/article/3248085/5-new-kitchen-appliances-ces-2024-smart-ai-grills-no-clean-ice-cream-one-touch-air-fryers-and-more
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Quartz</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://qz.com/at-ces-2024-tech-companies-are-transforming-the-kitche-1851155236"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://qz.com/at-ces-2024-tech-companies-are-transforming-the-kitche-1851155236
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">US News</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.usnews.com/news/best-states/nevada/articles/2024-01-10/tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.usnews.com/news/best-states/nevada/articles/2024-01-10/tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Morganton</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://morganton.com/news/local/column/amazing-kitchen-appliances-on-the-horizon/article_d6d91a2c-b61c-11ee-ae2c-23d39c1f1b57.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://morganton.com/news/local/column/amazing-kitchen-appliances-on-the-horizon/article_d6d91a2c-b61c-11ee-ae2c-23d39c1f1b57.html
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Tomorrow&apos;s World Today</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.tomorrowsworldtoday.com/consumer-products/kitchens-are-going-ai-at-ces-2024/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.tomorrowsworldtoday.com/consumer-products/kitchens-are-going-ai-at-ces-2024/
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">The Hill</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://thehill.com/homenews/ap/ap-technology/ap-tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://thehill.com/homenews/ap/ap-technology/ap-tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking/
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">TNT Max</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://tntmax.com/ces-2024-10-of-the-coolest-announcements/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://tntmax.com/ces-2024-10-of-the-coolest-announcements/
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">Radio 105 MT</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://radio105.mt/2024/01/11/chef-ais-one-touch-revolution/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://radio105.mt/2024/01/11/chef-ais-one-touch-revolution/
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">The Globe</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://www.theglobeandmail.com/investing/markets/commodities/SBK24/pressreleases/23245541/at-ces-2024-tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.theglobeandmail.com/investing/markets/commodities/SBK24/pressreleases/23245541/at-ces-2024-tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking/
            </a>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h3" mb={3}>
              Videos
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">
              IFBTA - International Food and Beverage Technology Association
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <iframe
              width={iframeWidth}
              height={iframeHeight}
              src="https://www.youtube.com/embed/sod7t1RCUDg"
              title="CHEF AI - IFBTA"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">The Spoon</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <iframe
              width={iframeWidth}
              height={iframeHeight}
              src="https://www.youtube.com/embed/tn1F61vMYUc"
              title="CHEF AI - The Spoon"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h5">CES 2024 - Dr. Know It All</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <iframe
              width={iframeWidth}
              height={iframeHeight}
              src="https://www.youtube.com/embed/ur1it7OZ89Q?start=490s"
              title="CHEF AI - Dr. Know It All"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
