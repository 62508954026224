/**

/** 
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import { PiBookOpenTextLight } from "react-icons/pi";
import { PiBellLight } from "react-icons/pi";
// import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { CiLogin } from "react-icons/ci";
// import LogoutIcon from "@mui/icons-material/Logout";

// Pages
import AboutUs from "layouts/pages/company/about-us";
import SingleArticle from "pages/Blogs/SingleArticle";
// import Shop from "pages/Apps/DesktopApp";

// Account
// import SignInBasicPage from "layouts/authentication/sign-in/basic";
import RedirectToAuth0 from "layouts/authentication/auth0/sign-in";
// import LogoutOfAuth0 from "layouts/authentication/auth0/sign-out";

const routes = [
  {
    name: "about us",
    icon: <PiBookOpenTextLight />,
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "updates",
    icon: <PiBellLight />,
    route: "/updates",
    component: <SingleArticle />,
  },
  // {
  //   name: "pre-order",
  //   icon: <ShoppingBasketIcon />,
  //   route: "/shop",
  //   component: <Shop />,
  // },
  {
    name: "login",
    icon: <CiLogin />,
    route: "/login",
    component: <RedirectToAuth0 />,
  },
  // {
  //   name: "logout",
  //   icon: <LogoutIcon />,
  //   route: "/logout",
  //   component: <LogoutOfAuth0 />,
  // },
];

export default routes;
